.main-login--page-wrapper {
  height: 100vh;
  width: 100vw;
  padding: 4rem 5%;
  flex-wrap: nowrap !important;
  overflow-y: auto;
}

.main-login--logo-wrapper > img {
  width: 90%;
	max-width: 25rem;
  margin-bottom: 1rem;
  align-self: center;
  display: block;
}

div.a {
	width: 50%;
}
