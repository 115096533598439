html {
	height: 100%;
}

body {
	height: 100%;
	font-family: 'Roboto', sans-serif;
}
.heading {
	font-weight: 700;
}
.subheading {
	font-weight: 300;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.ai-tracker {
	height: 0;
	flex-grow: 1;
}

/* Hide Recaptcha badge */
.grecaptcha-badge {
	visibility: hidden;
}
