#list-item {
	display: flex;
	min-height: 54px;
	align-items: center;
	position: relative;
	margin: 0 auto;
	cursor: auto;
}

#divider {
	margin: 0 auto;
}

#icon {
	fill: #70cde5;
	cursor: pointer;
}

#type {
	font-size: 70%;
}

#text-container {
	margin-right: 45px;
	cursor: pointer;
}

#text-container:hover {
	border-radius: 20px;
}

#content-p {
	margin-top: 5px;
	margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
	#list-item {
		width: 100%;
	}

	#divider {
		width: 100%;
	}
}
