.sidenav---sidenav---_2tBP {
	z-index: 90;
}

.app-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.displayed-component {
	height: 100%;
	overflow-y: auto;
}

.my-dataspaces-container {
	height: 100%;
}

.dataspace-view {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.dataspace-wrapper {
	height: 100%;
	overflow: hidden;
}

.dataspace-viewmanager-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.datatable-wrapper-fullscreen {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 91;
	display: flex;
	flex-direction: column;
}

.datatable-wrapper {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

#datatable-dataspace {
	width: 100% !important;
	min-width: 100% !important;
	height: 100%;
	display: flex;
	flex-direction: column;
	overscroll-behavior-x: none;
}

.p-datatable-scrollable-wrapper {
	flex-grow: 1;
	overflow-y: hidden;
}

/* Background is for fullscreen mode when there are not enough rows to cover the table space. */
.p-datatable-scrollable-view {
	height: 100%;
	display: flex;
	flex-direction: column;
	background: white;
}

.p-datatable-scrollable-body {
	height: 0;
	flex-grow: 1;
	overscroll-behavior-x: none;
}

/* Frozen section of DataTable to ignore tableWidth and fill frozenWidth */
.p-datatable-frozen-view > .p-datatable-scrollable-header,
.p-datatable-frozen-view > p-datatable-scrollable-body,
.p-datatable-frozen-view > .p-datatable-scrollable-footer {
	width: 100% !important;
}
