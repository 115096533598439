.forgotten-password--page-wrapper {
	height: 100vh;
  padding: 4rem 5%;
	display: flex;
	flex-direction: column;
  flex-wrap: nowrap !important;
}

.forgotten-password--logo-wrapper {
  display: flex;
  justify-content: center;
}

.forgotten-password--logo-wrapper > img {
	width: 60%;
	max-width: 25rem;
  align-self: center;
  display: block;
  padding-bottom: 2rem;
}

.forgotten-password--header {
	text-align: center;
}

.forgotten-password--secondary-header {
  text-align: center;
}

.forgotten-password--form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgotten-password--form-texfield {
  width: 100%;
  max-width: 35rem;
}