body {
	margin: 0;
	padding: 0;
}

.app-container {
	height: 100%;
	position: relative;
}

.displayed-component {
	margin-left: 65px;
}
