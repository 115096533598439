#my-dataspaces {
	margin: 10px;
}

#list-items {
	margin: 0 auto;
}

#center {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.p-tabmenu-nav {
	background: transparent !important;
	border-color: #47a8be;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
	border-color: #47a8be !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
	color: #47a8be !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
	padding-left: 0 !important;
	padding-right: 0 !important;
	background: transparent !important;
	margin-right: 1rem !important;
	color: #47a8be;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
	box-shadow: none !important;
}
