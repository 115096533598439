.chargify_form_col_container {
  margin: 10px;
}
.chargify_form_row {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.chargify_form_col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: center;
  flex: 1;
}
